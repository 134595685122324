import React, { useState, useEffect } from 'react';
import { View, Text, Modal, TouchableOpacity, TextInput, FlatList, StyleSheet, Image, ScrollView, Linking, Switch, Pressable, Dimensions } from 'react-native';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc, deleteField, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useUser } from './UserContext';
import genericProfilePicture from '../assets/generic.jpg';
import PromptSignUp from './PromptSignUp';

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;


const Friends = React.forwardRef(({ isVisible, onClose, isFriendsListPrivate }, ref) => {
    const db = getFirestore();
    const auth = getAuth();
    const { currentUserData, setCurrentUserData } = useUser();

    // State hooks
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [suggestedFriends, setSuggestedFriends] = useState([]);
    const [currentFriends, setCurrentFriends] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [activeTab, setActiveTab] = useState('suggested');
    const [userCache, setUserCache] = useState([]);
    const [isPrivate, setIsPrivate] = useState(isFriendsListPrivate);
    const [friendRequests, setFriendRequests] = useState([]);
    const [showSignupPopup, setShowSignupPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasSentRequestList, setHasSentRequestList] = useState({});
    const [showPromptSignUp, setShowPromptSignUp] = useState(false);
    const [showPrivacyWarning, setShowPrivacyWarning] = useState(false);

    useEffect(() => {
        setIsPrivate(isFriendsListPrivate);
    }, [isFriendsListPrivate]);

    useEffect(() => {
        if (!currentUserData && isVisible) {
            setShowPromptSignUp(true);
        }
    }, [isVisible, currentUserData]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchUsers = async () => {
            try {
                const usersRef = collection(db, "users");
                const querySnapshot = await getDocs(usersRef);
                const users = querySnapshot.docs
                    .map(doc => ({ ...doc.data(), email: doc.id }))
                    .filter(user => user.uid !== currentUserData?.uid && !user.isFriendsListPrivate); // Filter out private users
                setUserCache(users);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, [auth.currentUser, currentUserData]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchCurrentFriends = async () => {
            setIsLoading(true);
            try {
                const userEmail = auth.currentUser.email; // Ensure we get the email property
                if (!userEmail) {
                    console.error("User email is not available");
                    return;
                }

                const userDoc = await getDoc(doc(db, "users", userEmail));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const friendsData = userData.friends || {};
                    setCurrentFriends([
                        ...Object.keys(friendsData.following || {}).filter(uid => uid !== currentUserData?.uid),
                        ...Object.keys(friendsData.requestsSent || {}).filter(uid => uid !== currentUserData?.uid)
                    ]);
                    setFollowers(Object.values(friendsData.followers || {}).filter(follower => follower.uid !== currentUserData?.uid));
                    setFriendRequests(Object.values(friendsData.requests || {}).filter(request => request.uid !== currentUserData?.uid));
                    setHasSentRequestList(Object.keys(friendsData.requestsSent || {}).filter(requestSent => requestSent.uid !== currentUserData?.uid));
                }
            } catch (error) {
                console.error("Error fetching current friends and followers:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isVisible) {
            fetchCurrentFriends();
        }
    }, [isVisible, currentUserData, userCache]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchPrivacySetting = async () => {
            try {
                const userEmail = auth.currentUser.email;
                if (!userEmail) {
                    console.error("User email is not available");
                    return;
                }

                const userDoc = await getDoc(doc(db, "users", userEmail));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsPrivate(userData.isFriendsListPrivate || false);
                }
            } catch (error) {
                console.warn("Error fetching privacy setting:", error);
            }
        };

        fetchPrivacySetting();
    }, [auth.currentUser, currentUserData]); // Ensure this effect runs when currentUserData changes

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        if (searchText === '') {
            setSearchResults([]);
        } else {
            const results = userCache.filter(user => {
                const firstName = user.firstName?.toLowerCase() || '';
                const lastName = user.lastName?.toLowerCase() || '';
                const uid = user.uid?.toLowerCase() || '';
                const institutionName = user.organization?.name?.toLowerCase() || '';
                const institutionCode = user.organization?.code?.toLowerCase() || '';
                return user.accountType === 'Graduate' && (
                    firstName.includes(searchText.toLowerCase()) ||
                    lastName.includes(searchText.toLowerCase()) ||
                    uid.includes(searchText.toLowerCase()) ||
                    institutionName.includes(searchText.toLowerCase()) ||
                    institutionCode.includes(searchText.toLowerCase())
                );
            }).filter(user => user.uid !== currentUserData?.uid && !user.isFriendsListPrivate); // Filter out private users
            setSearchResults(results);
            console.log("Search Results:", results);
        }
    }, [searchText, userCache, currentFriends, hasSentRequestList, friendRequests]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchSuggestedFriends = () => {
            const currentUser = userCache.find(user => user.uid === currentUserData.uid);
            if (!currentUserData) {
                return;
            }

            const sameInstitution = userCache.filter(user => user.accountType === 'Graduate' && user.organization?.name === currentUserData.organization?.name && user.email !== currentUserData.email && !currentFriends.includes(user.uid) && !user.isFriendsListPrivate);
            const similarFriends = userCache.filter(user => {
                const friendIds = Object.keys(user.friends?.following || {});
                return user.accountType === 'Graduate' && friendIds.some(friendId => currentFriends.includes(friendId)) && user.email !== currentUserData.email && !currentFriends.includes(user.uid) && !user.isFriendsListPrivate;
            });
            const randomUsers = userCache.filter(user => user.accountType === 'Graduate' && user.email !== currentUserData.email && !currentFriends.includes(user.uid) && !user.isFriendsListPrivate);

            const suggestions = [...new Set([...sameInstitution, ...similarFriends, ...randomUsers])]
                .filter(user => user.uid !== currentUserData?.uid)
                .filter(user => !currentUserData?.friends?.following?.hasOwnProperty(user.uid))
                .slice(0, 5);
            setSuggestedFriends(suggestions);
        };

        fetchSuggestedFriends();
    }, [userCache, currentFriends, currentUserData]);

    const addFriend = async (email) => {
        try {
            if (!auth.currentUser || !currentUserData) {
                console.error("User is not authenticated or current user data is not available");
                return;
            }

            const userEmail = auth.currentUser.email;
            if (!userEmail || !email) {
                console.error("User email or friend email is not available");
                return;
            }

            const userDoc = doc(db, "users", userEmail);
            const userSnapshot = await getDoc(userDoc);
            if (!userSnapshot.exists()) {
                console.error("Current user document not found");
                return;
            }

            const userData = userSnapshot.data();
            const friendDoc = doc(db, "users", email);

            // Get the friend's data
            const friendSnapshot = await getDoc(friendDoc);
            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }
            const friendData = friendSnapshot.data();

            // Ensure all necessary fields are available
            const { uid: currentUserUid, email: currentUserEmail, firstName: currentUserFirstName, lastName: currentUserLastName, profilePictureURL: currentUserProfilePictureURL } = currentUserData;
            const { uid: friendUid, email: friendEmail, firstName: friendFirstName, lastName: friendLastName, profilePictureURL: friendProfilePictureURL } = friendData;

            if (!currentUserUid || !currentUserEmail || !friendUid || !friendEmail) {
                console.error("Missing necessary user data");
                return;
            }

            // Update the current user's following list
            await updateDoc(userDoc, {
                [`friends.following.${friendUid}`]: {
                    email: friendEmail,
                    name: `${friendFirstName || ""} ${friendLastName || ""}`,
                    profilePictureURL: friendProfilePictureURL || ""
                }
            });

            // Update the friend's followers list
            await updateDoc(friendDoc, {
                [`friends.followers.${currentUserUid}`]: {
                    email: currentUserEmail,
                    name: `${currentUserFirstName || ""} ${currentUserLastName || ""}`,
                    profilePictureURL: currentUserProfilePictureURL || ""
                }
            });

            // Optionally update local state if needed
            setCurrentFriends([...currentFriends, friendUid]);
            setFollowers([...followers, currentUserUid]);
            setSuggestedFriends(suggestedFriends.filter(user => user.email !== email)); // Remove from suggestions
        } catch (error) {
            console.error("Error adding friend:", error);
        }
    };

    const unfollowFriend = async (email) => {
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);

            // Get the friend's data to find their UID
            const friendSnapshot = await getDoc(friendDoc);
            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }
            const friendData = friendSnapshot.data();
            const friendUid = friendData.uid;

            await updateDoc(userDoc, {
                [`friends.following.${friendUid}`]: deleteField()
            });
            await updateDoc(friendDoc, {
                [`friends.followers.${auth.currentUser.email}`]: deleteField()
            });

            // Update local state
            setCurrentFriends(currentFriends.filter(uid => uid !== friendUid));
            setFollowers(followers.filter(uid => uid !== currentUserData.uid));
        } catch (error) {
            console.error("Error unfollowing friend:", error);
        }
    };

    const sendFriendRequest = async (email) => {
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);

            // Get the friend's data to find their UID
            const friendSnapshot = await getDoc(friendDoc);
            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }
            const friendData = friendSnapshot.data();
            const friendUid = friendData.uid;

            // Ensure all necessary fields are available
            const { uid: currentUserUid, email: currentUserEmail, firstName: currentUserFirstName, lastName: currentUserLastName, profilePictureURL: currentUserProfilePictureURL } = currentUserData;

            await updateDoc(userDoc, {
                [`friends.requestsSent.${friendUid}`]: true
            });
            await updateDoc(friendDoc, {
                [`friends.requests.${currentUserUid}`]: {
                    email: currentUserEmail,
                    name: `${currentUserFirstName || ""} ${currentUserLastName || ""}`,
                    profilePictureURL: currentUserProfilePictureURL || "",
                    uid: currentUserUid
                }
            });

            // Update local state
            setCurrentFriends([...currentFriends, friendUid]); // Add to current friends to show in following list
            setCurrentUserData({
                ...currentUserData,
                friends: {
                    ...currentUserData.friends,
                    requestsSent: {
                        ...currentUserData.friends.requestsSent,
                        [friendUid]: true
                    }
                }
            });
        } catch (error) {
            console.error("Error sending friend request:", error);
        }
    };

    const cancelFriendRequest = async (email) => {
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);

            // Get the friend's data to find their UID
            const friendSnapshot = await getDoc(friendDoc);
            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }
            const friendData = friendSnapshot.data();
            const friendUid = friendData.uid;

            await updateDoc(userDoc, {
                [`friends.requestsSent.${friendUid}`]: deleteField()
            });
            await updateDoc(friendDoc, {
                [`friends.requests.${currentUserData.uid}`]: deleteField()
            });

            // Update local state
            setCurrentFriends(currentFriends.filter(uid => uid !== friendUid));
            setCurrentUserData({
                ...currentUserData,
                friends: {
                    ...currentUserData.friends,
                    requestsSent: {
                        ...currentUserData.friends.requestsSent,
                        [friendUid]: undefined
                    }
                }
            });

            // Update hasSentRequest state
            setFriendRequests(friendRequests.filter(request => request.uid !== friendUid));
            setHasSentRequestList(hasSentRequestList.filter(request => request.uid !== friendUid));
        } catch (error) {
            console.error("Error canceling friend request:", error);
        }
    };

    const acceptFriendRequest = async (friendEmail) => {
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", friendEmail);
            const friendData = await getDoc(friendDoc);

            if (!friendData.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendDetails = friendData.exists() ? friendData.data() : {};
            const uid = friendDetails.uid;

            const profilePictureURL = currentUserData?.profilePictureURL || "";
            const name = `${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`;
            const email = currentUserData?.email || "";

            await updateDoc(userDoc, {
                // Add friend to my followers list
                [`friends.followers.${uid}`]: {
                    email: friendDetails.email,
                    name: `${friendDetails.firstName || ""} ${friendDetails.lastName || ""}`,
                    profilePictureURL: friendDetails.profilePictureURL || "",
                    uid: friendDetails.uid
                },
                // Delete friend from my requests list
                [`friends.requests.${uid}`]: deleteField()
            });

            await updateDoc(friendDoc, {
                // Add self to friends following list
                [`friends.following.${currentUserData.uid}`]: {
                    email: email,
                    name: name,
                    profilePictureURL: profilePictureURL,
                    uid: currentUserData.uid
                },
                // Delete self from new friends requestsSent list
                [`friends.requestsSent.${currentUserData.uid}`]: deleteField()
            });

            setFollowers([...followers, uid]);
            setFriendRequests(friendRequests.filter(request => request.uid !== uid));
        } catch (error) {
            console.error("Error accepting friend request:", error);
        }
    };

    const declineFriendRequest = async (friendEmail) => {
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", friendEmail);

            const friendData = await getDoc(friendDoc);
            const friendDetails = friendData.exists() ? friendData.data() : {};
            const uid = friendDetails.uid;

            if (!friendData.exists()) {
                console.error("Friend not found");
                return;
            }

            // Delete user from my requests list
            await updateDoc(userDoc, {
                [`friends.requests.${uid}`]: deleteField(),
                [`friends.followers.${uid}`]: deleteField()
            });

            // Delete self from user's requestsSent list
            await updateDoc(friendDoc, {
                [`friends.requestsSent.${currentUserData.uid}`]: deleteField()
            });

            setFollowers([followers.filter(follower => follower.uid !== uid)]);
            setFriendRequests(friendRequests.filter(request => request.uid !== uid));
        } catch (error) {
            console.error("Error declining friend request:", error);
        }
    };

    const fetchPrivacySetting = async () => {
        if (!currentUserData || !currentUserData.email) {
            console.error("User is not authenticated or email is not available");
            return;
        }

        const userDoc = await getDoc(doc(db, "users", currentUserData.email));
        if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsPrivate(userData.isFriendsListPrivate || false);
        } else {
            setIsPrivate(false); // Default to false if the field is not found
        }
    };

    useEffect(() => {
        fetchPrivacySetting();
    }, [currentUserData]);

    const updatePrivacySetting = async (isPrivate) => {
        const generateUIC = () => {
            return Math.random().toString(36).substr(2, 9); // Simple UIC generation logic
        };
        
        try {
            if (!currentUserData || !currentUserData.email) {
                console.error("User is not authenticated or email is not available");
                return;
            }
 
            const userDoc = doc(db, "users", currentUserData.email);
            const userSnapshot = await getDoc(userDoc);
            let uic = userSnapshot.data().uic;
 
            if (isPrivate && !uic) {
                uic = generateUIC(); // Function to generate a unique invite code
            }
 
            await updateDoc(userDoc, {
                isFriendsListPrivate: isPrivate,
                ...(isPrivate && { uic }) // Only add UIC if the account is private
            });
            setIsPrivate(isPrivate); // Update local state
            // alert(`Privacy updated to: ${isPrivate ? 'Private' : 'Public'}`);
        } catch (error) {
            console.error("Error updating privacy setting:", error);
        }
    };

    const handlePrivacyToggle = () => {
        if (isPrivate) {
            setShowPrivacyWarning(true);
        } else {
            setIsPrivate(true);
            updatePrivacySetting(true);
        }
    };

    const confirmPrivacyToggle = () => {
        setIsPrivate(false);
        setShowPrivacyWarning(false);
        updatePrivacySetting(false);
    };

    const renderFriendItem = ({ item }) => {
        if (!item) return null;

        const UID = item.uid;
        const isFollowing = currentFriends.includes(UID);
        const isFollower = followers.includes(UID);
        const hasSentRequest = Array.isArray(hasSentRequestList) && hasSentRequestList.includes(UID);
        const isMutualFriend = isFollowing && isFollower;

        // Check if the user is the current user
        if (item.email === auth.currentUser.email) {
            return null;
        }

        return (
            <View style={styles.friendItem}>
                <Image source={{ uri: item.profilePictureURL || genericProfilePicture }} style={styles.profilePicture} />
                <Text>{item.firstName || 'Unknown'} {item.lastName || 'User'}</Text>
                <View style={styles.buttonContainer}>
                    {item.pages?.registry && (
                        <TouchableOpacity onPress={() => Linking.openURL(item.pages.registry)} style={styles.visitButton}>
                            <Text style={styles.visitButtonText}>Registry Page</Text>
                        </TouchableOpacity>
                    )}
                    {item.pages?.events && (
                        <TouchableOpacity onPress={() => Linking.openURL(item.pages.events)} style={styles.visitButton}>
                            <Text style={styles.visitButtonText}>Announcement Page</Text>
                        </TouchableOpacity>
                    )}
                    {hasSentRequest ? (
                        <TouchableOpacity onPress={() => cancelFriendRequest(item.email)} style={styles.cancelRequestButton}>
                            <Text style={styles.cancelRequestButtonText}>Cancel Request</Text>
                        </TouchableOpacity>
                    ) : isMutualFriend ? (
                        <TouchableOpacity onPress={() => unfollowFriend(item.email)} style={styles.unfollowButton}>
                            <Text style={styles.unfollowButtonText}>Unfollow</Text>
                        </TouchableOpacity>
                    ) : item.isFriendsListPrivate ? (
                        <TouchableOpacity onPress={() => sendFriendRequest(item.email)} style={styles.addButton}>
                            <Text style={styles.addButtonText}>Send Follow Request</Text>
                        </TouchableOpacity>
                    ) : isFollowing ? (
                        <TouchableOpacity onPress={() => unfollowFriend(item.email)} style={styles.unfollowButton}>
                            <Text style={styles.unfollowButtonText}>Unfollow</Text>
                        </TouchableOpacity>
                    ) : (
                        <TouchableOpacity onPress={() => addFriend(item.email)} style={styles.addButton}>
                            <Text style={styles.addButtonText}>Add Friend</Text>
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        );
    };

    const renderRequestItem = ({ item }) => {
        if (!item) return null;
        const UID = item.uid;

        return (
            <View style={styles.friendItem}>
                <Image source={{ uri: item.profilePictureURL || genericProfilePicture }} style={styles.profilePicture} />
                <Text>{item.name || 'Unknown User'}</Text>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity onPress={() => acceptFriendRequest(item.email)} style={styles.acceptButton}>
                        <Text style={styles.acceptButtonText}>Accept</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => declineFriendRequest(item.email)} style={styles.declineButton}>
                        <Text style={styles.declineButtonText}>Decline</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    const updateExistingUsers = async () => {
        const usersRef = collection(db, "users");
        const querySnapshot = await getDocs(usersRef);
        querySnapshot.forEach(async (doc) => {
            const userData = doc.data();
            let friendsData = userData.friends || {};

            if (!friendsData.following || !friendsData.followers || !friendsData.requests) {
                const following = friendsData.following ? Object.keys(friendsData.following) : [];
                const followers = friendsData.followers ? Object.keys(friendsData.followers) : [];
                const requests = friendsData.requests ? Object.keys(friendsData.requests) : [];

                const newFriendsData = {
                    following: following.reduce((acc, email) => ({ ...acc, [email]: true }), {}),
                    followers: followers.reduce((acc, email) => ({ ...acc, [email]: true }), {}),
                    requests: requests.reduce((acc, email) => ({ ...acc, [email]: true }), {})
                };

                await updateDoc(doc.ref, {
                    friends: newFriendsData
                });
            }
        });
    };

    useEffect(() => {
        updateExistingUsers();
    }, []);

    useEffect(() => {
        const fetchPrivacySetting = async () => {
            if (!auth.currentUser) {
                console.error("User is not authenticated");
                return;
            }

            try {
                const userEmail = auth.currentUser.email;
                const userDoc = await getDoc(doc(db, "users", userEmail));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsPrivate(userData.isFriendsListPrivate || false);
                }
            } catch (error) {
                console.error("Error fetching privacy setting:", error);
            }
        };

        if (isVisible) {
            fetchPrivacySetting();
        }
    }, [isVisible, auth.currentUser]);

    return (
        <>
            <PromptSignUp isVisible={showPromptSignUp} onClose={() => setShowPromptSignUp(false)} redirectPage="Friends" />
            <Modal animationType="slide" transparent={true} visible={isVisible && !showPromptSignUp} onRequestClose={onClose}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={styles.header}>
                            <>
                                <Image source={{ uri: currentUserData?.profilePictureURL || genericProfilePicture }} style={styles.headerImage} />
                                <Text style={styles.modalText}>Friends</Text>
                            </>
                            <View style={styles.privacyToggle}>
                                <Text style={styles.privacyToggleText}>
                                    {isPrivate ? 'Private' : 'Public'}
                                </Text>
                                <Switch
                                    value={isPrivate}
                                    onValueChange={handlePrivacyToggle}
                                />
                            </View>
                        </View>
                        <TextInput
                            style={styles.input}
                            onChangeText={(text) => {
                                setSearchText(text);
                                setActiveTab('search');
                            }}
                            value={searchText}
                            placeholder="Search by name, UID, institution name, or code"
                        />
                        <View style={styles.tabContainer}>
                            <TouchableOpacity onPress={() => setActiveTab('suggested')} style={activeTab === 'suggested' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Suggested</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setActiveTab('following')} style={activeTab === 'following' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Following</Text>       
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setActiveTab('followers')} style={activeTab === 'followers' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Followers</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setActiveTab('requests')} style={activeTab === 'requests' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Requests</Text>
                            </TouchableOpacity>
                        </View>
                        <ScrollView style={styles.scrollView}>
                            {isLoading ? (
                                <Text>Loading...</Text>
                            ) : (
                                <>
                                    {searchResults.length > 0 && (
                                        <>
                                            <Text style={styles.dividerText}>Search Results</Text>
                                            <FlatList
                                                data={searchResults}
                                                renderItem={renderFriendItem}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'suggested' && (
                                        <>
                                            <Text style={styles.dividerText}>Suggested Friends</Text>
                                            <FlatList
                                                data={suggestedFriends}
                                                renderItem={renderFriendItem}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'following' && (
                                        <>
                                            <Text style={styles.dividerText}>Following</Text>
                                            <FlatList
                                                data={currentFriends.map(uid => userCache.find(user => user.uid === uid)).filter(Boolean)}
                                                renderItem={({ item }) => renderFriendItem({ item })}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'followers' && (
                                        <>
                                            <Text style={styles.dividerText}>Followers</Text>
                                            <FlatList
                                                data={followers.map(uid => userCache.find(user => user.uid === uid)).filter(Boolean)}
                                                renderItem={({ item }) => renderFriendItem({ item })}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'requests' && (
                                        <>
                                            <Text style={styles.dividerText}>Friend Requests</Text>
                                            <FlatList
                                                data={friendRequests}
                                                renderItem={renderRequestItem}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </ScrollView>
                        <TouchableOpacity style={[styles.button, styles.buttonClose]} onPress={onClose}>
                            <Text style={styles.textStyle}>Close</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
            <Modal transparent={true} animationType="slide" visible={showPrivacyWarning} onRequestClose={() => setShowPrivacyWarning(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Privacy Warning</Text>
                        <Text style={styles.warningText}>Making your page public will share your info with anyone, including your address if you create a registry.</Text>
                        <Pressable style={styles.modalButton} onPress={confirmPrivacyToggle}>
                            <Text style={styles.modalButtonText}>I Understand</Text>
                        </Pressable>
                        <Pressable style={styles.closeModalButton} onPress={() => setShowPrivacyWarning(false)}>
                            <Text style={styles.modalButtonText}>Cancel</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
        </>
    );
});

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        flex: 1,
        width: '90%',
        margin: 20,
        backgroundColor: '#fefde2',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
        elevation: 5,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 5
    },
    headerImage: {
        width: 55,
        height: 55,
        borderRadius: 100,
    },
    modalText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    input: {
        width: '100%',
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
    },
    tabContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    activeTab: {
        flex: 1,
        padding: 10,
        backgroundColor: '#4CAF50',
        alignItems: 'center',
        borderRadius: 5,
        marginRight: isMobile ? 5 : 10,
    },
    inactiveTab: {
        flex: 1,
        padding: 10,
        backgroundColor: '#e0e0e0',
        alignItems: 'center',
        borderRadius: 5,
        marginRight: isMobile ? 5 : 10,
    },
    tabText: {
        fontSize: isMobile ? 10 : 16,
        color: 'white'
    },
    scrollView: {
        width: '100%',
    },
    friendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    profilePicture: {
        width: isMobile ? 30 : 40,
        height: isMobile ? 30 : 40,
        borderRadius: 20,
        marginRight: 10,
    },
    addButton: {
        marginLeft: 'auto',
        backgroundColor: '#4CAF50',
        padding: 5,
        borderRadius: 5,
    },
    addButtonText: {
        fontSize: isMobile ? 10 : 16,
        color: 'white',
    },
    unfollowButton: {
        marginLeft: 'auto',
        backgroundColor: '#FF0000',
        padding: 5,
        borderRadius: 5,
    },
    unfollowButtonText: {
        color: 'white',
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
    },
    buttonClose: {
        backgroundColor: 'red',
        marginTop: 20,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    dividerText: {
        fontSize: 16,
        fontWeight: 'bold',
        marginVertical: 10,
        textAlign: 'center',
    },
    visitButton: {
        backgroundColor: '#2196F3',
        padding: 5,
        borderRadius: 5,
        marginRight: isMobile ? 0 : 10, // Remove right margin on mobile
        marginBottom: isMobile ? 5 : 0, // Add bottom margin on mobile
    },
    visitButtonText: {
        color: 'white',
        fontSize: isMobile ? 10 : 16,
        textAlign: 'center', // Center text
    },
    privacyToggle: {
        backgroundColor: '#FF9800',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        marginBottom: 10,
    },
    privacyToggleText: {
        color: 'white',
        fontWeight: 'bold',
    },
    buttonContainer: {
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        justifyContent: isMobile ? 'center' : 'flex-start', // Center buttons on mobile
    },
    buttonSignUp: {
        backgroundColor: 'green',
        marginTop: 20,
    },
    cancelRequestButton: {
        marginLeft: 'auto',
        backgroundColor: '#FF0000',
        padding: 5,
        borderRadius: 5,
    },
    cancelRequestButtonText: {
        color: 'white',
    },
    acceptButton: {
        marginLeft: 'auto',
        backgroundColor: '#4CAF50',
        padding: 5,
        borderRadius: 5,
    },
    acceptButtonText: {
        color: 'white',
    },
    declineButton: {
        marginLeft: 10,
        backgroundColor: '#FF0000',
        padding: 5,
        borderRadius: 5,
    },
    declineButtonText: {
        color: 'white',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fefde2',
        padding: 20,
        borderRadius: 10,
        width: '80%',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    warningText: {
        marginBottom: 20,
    },
    modalButton: {
        backgroundColor: '#4CAF50',
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
    },
    closeModalButton: {
        backgroundColor: '#FF0000',
        padding: 10,
        borderRadius: 5,
    },
    modalButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
});

export default Friends;